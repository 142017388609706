import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import { ExceededReason } from "../../../../../store/DowntimeRegistrations/Types";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormTextArea } from "../../../../../components/core/07-organisms/Form/TextArea";
import { Blockquote } from "../../../../../components/core/05-atoms/Blockquote";
import { ThemePaletteState } from "../../../../../theme/_Types";
import { Icon } from "../../../../../components/core/05-atoms/Icon";
import { checkDateUntil, hasExceededDateValidation } from "./utils";
import { ValidationStatus } from "../../../../../components/core/07-organisms/Form/_Types";

/**
 *
 */
interface Props {
    fields: Partial<ExceededReason>;
    otherFields?: Partial<ExceededReason>;
    onChange: (fields: Partial<ExceededReason>) => void;
    title: string;
    defaultMinDate?: Date;
    otherFieldsLabel?: string;
}

/**
 *
 * @param props
 */
export const ExceededFields: React.FC<Props> = (props) => {

    const onChange: (newValues: Partial<ExceededReason>) => void = (newValues) => {
        const updatedStore = {...props.fields, ...newValues};
        props.onChange(updatedStore);
    };

    return(
        <FieldSet title={props.title}>
            {hasExceededDateValidation(
                props.fields, props.otherFields, props.otherFieldsLabel, "").status === ValidationStatus.ERROR && (
                <Blockquote
                    background
                    theme={{
                        paletteState: ThemePaletteState.DANGER,
                        margin: {
                            "": { b : 3},
                            "sm": { b : 4},
                        },
                    }}
                >
                    <b style={{color: "var(--scl-color-state-contrast--danger)"}}>
                        <Icon name={"fas fa-exclamation-triangle"} />
                        &nbsp;&nbsp;Let op, u heeft niet alles correct ingevuld.
                    </b>
                    <div style={{color: "var(--scl-color-state-contrast--danger)", paddingTop: "5px"}}>
                        {hasExceededDateValidation(props.fields, props.otherFields, props.otherFieldsLabel, "").message}
                    </div>
                </Blockquote>
            )}
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Startdatum"
                        type="date"
                        required
                        minDate={props.defaultMinDate}
                        maxDate={new Date(Date.now() + 1)}
                        onChange={(value?: string | number) => onChange({
                            downtimeDateStarted: value?.toString() ?? "",
                            downtimeDateEnded: checkDateUntil(value ? value.toString()
                                : undefined, props.fields.downtimeDateEnded),
                        })}
                        value={props.fields.downtimeDateStarted}
                        validationFunction={
                            (value: string | number) => {
                                if(hasValueValidation(value, "").status === ValidationStatus.SUCCESS){
                                    return hasExceededDateValidation(
                                        props.fields, props.otherFields, props.otherFieldsLabel, "", "");
                                }
                                return hasValueValidation(value,"");
                            }
                        }
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Starttijd"
                        type="time"
                        required
                        onChange={(value?: string | number) => onChange({
                            downtimeTimeStarted: value?.toString(),
                        })}
                        value={props.fields.downtimeTimeStarted}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value,
                                    "")}
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Einddatum"
                        type="date"
                        required
                        maxDate={new Date(Date.now() + 1)}
                        minDate={props.fields.downtimeDateStarted
                            ? new Date(props.fields.downtimeDateStarted) : props.defaultMinDate}
                        onChange={(value?: string | number) => onChange({
                            downtimeDateEnded: value?.toString() ?? "",
                        })}
                        value={props.fields.downtimeDateEnded}
                        validationFunction={
                            (value: string | number) => {
                                if(hasValueValidation(value, "").status === ValidationStatus.SUCCESS){
                                    return hasExceededDateValidation(
                                        props.fields, props.otherFields, props.otherFieldsLabel, "", "");
                                }
                                return hasValueValidation(value,"");
                            }
                        }
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Eindtijd"
                        type="time"
                        required
                        onChange={(value?: string | number) => onChange({
                            downtimeTimeEnded: value?.toString(),
                        })}
                        value={props.fields.downtimeTimeEnded}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value,
                                    "")}
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 12 }}
                >
                    <FormTextArea
                        type="textArea"
                        required
                        label="Toelichting"
                        onChange={(value?: string | number) => onChange({
                            reason: value?.toString(),
                        })}
                        value={props.fields.reason}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value,
                                    "")}
                    />
                </Grid>
            </Div>
        </FieldSet>
    );
};
