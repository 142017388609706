/* eslint-disable max-len */
export const I18nAppTranslationsNL = {
    "app.general": "Algemeen",
    "app.yes": "Ja",
    "app.no": "Nee",
    "app.pdf": "PDF",
    "app.csv": "CSV",
    "app.date-of-update": "Update datum",
    "app.make-a-choice": "Maak een keuze",
    "app.validation.has-value-message": "{{label}} ingevuld.",
    "app.06-molecules.downloadpdfbutton.no-download": "Geen download beschikbaar",
    "app.go-to-link": "Ga naar link",
    "app.home.title": "Welkom,",
    "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
    "app.remote-error": "Kan geen (geldige) verbinding maken.",
    "app.fatal-remote-error": "Wij weten niet wat er mis is!",
    "app.remote-init-error": "Geen API beschikbaar.",
    "app.no-id-message-error": "Geen id beschikbaar.",
    "app.remote-init-message":
        "Kan geen (geldige) verbinding maken.",
    "app.notification": "Notificatie",
    "app.warning": "Waarschuwing",
    "app.store.getremoteerrormessage.status": "Status:",
    "app.store.getremoteerrormessage.error": "Foutmelding:",
    "app.not-authorized.title": "Niet geautoriseerd.",
    "app.not-authorized.message":
        "U bent niet geautoriseerd, hierdoor kunnen wij geen verbinding met de API maken, ververs de pagina!",
    "app.disclaimer-title": "Juridische mededeling",
    // new
    "app.continue": "Ga verder",
    "app.cancel": "Annuleer",
    "app.no-role-active": "Geen rol actief",
    "app.email": "E-mail",
    "app.update": "Wijzig",
    "app.Loading..": "Laden..",
    "app.Loading role..": "Rol laden..",
    "app.retrieving-role(s)..": "Rol(len) laden..",
    "app.disclaimer": "Disclaimer",
    "app.dashboard": "Dashboard",
    "app.instruction-video": "Instructievideo",
    "app.manage-roles": "Beheer rollen",
    "app.agree": "Accepteer",
    "app.decline": "Weiger",
    "app.close": "Sluiten",
    "app.assign-roles": "Rollen toewijzen",
    "app.assign-role": "Rol toewijzen",
    "app.reports": "Rapporten",
    "app.step": "Stap",
    "app.summary": "Samenvatting",
    "app.not-all-content-can-be-loaded": "Niet alle content kan geladen worden.",
    "app.update-role": "Wijzig rol",
    "app.add-role": "Rol toevoegen",
    "app.email-provided": "E-mail opgegeven",
    "app.clear": "Wis",
    "app.search": "Zoeken",
    "app.actions": "Acties",
    "app.name": "Naam",
    "app.company": "Bedrijf",
    "app.label": "Label",
    "app.brand": "Merk",
    "app.assign": "Toewijzen",
    "app.claude-thinks-something-went-wrong": "Wij denken dat er iets is misgegaan!",
    "app.07-organisms.form.field.utils.length--error": "Geef {{max}} karakters op.",
    "app.makeachoice": "Maak een keuze",
};
