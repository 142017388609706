import { Page } from "../components/core/09-views/01-sections/Page";
import { Exceeded } from "./DowntimeRegistrations/Types";

/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */

/**
 *
 */
export enum Pages { }

/**
 *
 */
export enum PageTabs {
}

export interface Tab {
    key: string;
    title: string;
    description: string;
    component?: React.FunctionComponent | React.ComponentState;
}

export interface DynamicTab {
    title: string;
    results: Object;
    // schema: OAS.Schema;
}

export type TabRef = Record<string, DynamicTab>;

export interface Page {
    title: string;
    description: string;
    path: string;
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    // parameters?: OAS.Parameter[];
    pagination?: boolean;
    // responses?: OAS.Responses;
    parent?: Page;
}

export type PageRef = Record<string, Page>;

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
};

/**
 *
 */
export const PageTabsDisplay: Readonly<Record<PageTabs, Tab>> = {
};

/**
 *
 */
export const ExceededDisplay: Readonly<Record<Exceeded, string>> = {
    [Exceeded.NONE]: "Geen verwijt",
    [Exceeded.DEALER]: "Derden",
    [Exceeded.CUSTOMER]: "Klant",
    [Exceeded.DEALER_AND_CUSTOMER]: "Derden en klant",
};
