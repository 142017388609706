import moment from "moment";
import React from "react";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Blockquote } from "../../../../../components/core/05-atoms/Blockquote";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { FormGroup } from "../../../../../components/core/07-organisms/Form/Group";
import { RTE } from "../../../../../components/core/07-organisms/RTE";
import { ExceededDisplay } from "../../../../../store/AppDisplays";
import { Exceeded, FormRegisterDRAObjectObjectResponse } from "../../../../../store/DowntimeRegistrations/Types";
import { ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { formatDateString } from "../../../../../utils/FormatHelpers";
import { formatLicensePlate, isValidLicensePlate } from "../../../../../utils/LicensePlate";

/**
 *
 */
interface Props {
    results: FormRegisterDRAObjectObjectResponse;
}

/**
 *
 * @param props
 */
export const Summary: React.FC<Props> = (props) => (
    <Container
        theme={{
            shadow: ThemeShadowSizes.SMALL,
            palette: ThemePalette.CONTRAST_SECONDARY,
            padding: {
                "": { y: 3 },
                "sm": { y: 4 },
            },
            margin: {
                "": { b: 3 },
                "sm": { b: 4 },
            },
        }}
    >
        <Div className="scl-b-row">
            <Grid
                size={{ xs: 12, md: 6 }}
            >
                <FormGroup label="Dealer">
                    <RTE>
                        <h4>{props.results.content?.supplier?.name}</h4>
                    </RTE>
                </FormGroup>
            </Grid>
            <Grid
                size={{ xs: 12, md: 6 }}
            >
                <FormGroup label="Onderhouds/reparatiedatum">
                    <RTE>
                        <h4>{formatDateString(props.results.content?.maintenanceDateStarted)}</h4>
                    </RTE>
                </FormGroup>
            </Grid>
        </Div>
        <Div className="scl-b-row">
            <Grid
                size={{ xs: 12, md: 6 }}
            >
                <FormGroup label="Kenteken">
                    <RTE>
                        <h4>{isValidLicensePlate(props.results?.content?.vehicle?.licensePlate)
                            ? formatLicensePlate(props.results?.content?.vehicle?.licensePlate)
                            : props.results?.content?.vehicle?.licensePlate?.toUpperCase()}</h4>
                    </RTE>
                </FormGroup>
            </Grid>
            <Grid
                size={{ xs: 12, md: 6 }}
            >
                <FormGroup label="Downtime">
                    <RTE>
                        <h4>{props.results.content?.downtime}</h4>
                    </RTE>
                </FormGroup>
            </Grid>
        </Div>
        <FieldSet title="Onderhoudsperiode">
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Startdatum"
                        asSummary
                        value={moment(props.results?.content?.downtimeDateStarted).format("DD-MM-YYYY")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Starttijd"
                        asSummary
                        value={props.results?.content?.downtimeTimeStarted}
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Einddatum"
                        asSummary
                        value={moment(props.results?.content?.downtimeDateEnded).format("DD-MM-YYYY")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        label="Eindtijd"
                        asSummary
                        value={props.results?.content?.downtimeTimeEnded}
                    />
                </Grid>
            </Div>
        </FieldSet>
        {(props.results?.content?.exceeded && props.results.content.exceeded !== "none") && (
            <FieldSet title="Verwijtbare downtime">
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Verwijtbare downtime door"
                            asSummary
                            value={ExceededDisplay[props.results?.content?.exceeded as Exceeded]}
                        />
                    </Grid>
                </Div>
                {props.results.content.exceededByDealer && (
                    <FieldSet title={"Verwijtbaar aan derden"}>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Startdatum"
                                    asSummary
                                    type="date"
                                    value={props.results.content.exceededByDealer.downtimeDateStarted}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Starttijd"
                                    asSummary
                                    value={props.results.content.exceededByDealer.downtimeTimeStarted}
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Einddatum"
                                    asSummary
                                    type="date"
                                    value={props.results.content.exceededByDealer.downtimeDateEnded}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Eindtijd"
                                    type="time"
                                    asSummary
                                    value={props.results.content.exceededByDealer.downtimeTimeEnded}
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 12 }}
                            >
                                <FormGroup label="Toelichting">
                                    <RTE>
                                        {props.results.content?.exceededByDealer.reason}
                                    </RTE>
                                </FormGroup>
                            </Grid>
                        </Div>
                    </FieldSet>)}
                {props.results.content.exceededByCustomer && (
                    <FieldSet title={"Verwijtbaar aan klant"}>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Startdatum"
                                    asSummary
                                    type="date"
                                    value={props.results.content.exceededByCustomer.downtimeDateStarted}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Starttijd"
                                    asSummary
                                    value={props.results.content.exceededByCustomer.downtimeTimeStarted}
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Einddatum"
                                    asSummary
                                    type="date"
                                    value={props.results.content.exceededByCustomer.downtimeDateEnded}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormFields.FormField
                                    label="Eindtijd"
                                    type="time"
                                    asSummary
                                    value={props.results.content.exceededByCustomer.downtimeTimeEnded}
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 12 }}
                            >
                                <FormGroup label="Toelichting">
                                    <RTE>
                                        {props.results.content?.exceededByCustomer.reason}
                                    </RTE>
                                </FormGroup>
                            </Grid>
                        </Div>
                    </FieldSet>)}
            </FieldSet>)}
        <Div
            className="scl-b-row"
            theme={{
                margin: {
                    "": { b: 3 },
                    "sm": { b: 4 },
                },
            }}
        >
            <Grid
                size={{ xs: 12, md: 12 }}
            >
                <Blockquote
                    theme={{
                        palette: ThemePalette.BRAND_ACCENT,
                    }}
                >
                    <RTE><b>Het bovenstaande is naar waarheid ingevuld.<br />
                Eventuele documenten die aan de registratie ten grondslag
                liggen en van toepassing zijn, heeft u in uw bezit en dient u
                te kunnen overleggen.</b>
                    </RTE>
                </Blockquote>
            </Grid>
        </Div>
    </Container>
);
