import "core-js";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    BrowserRouter as Router, Route, RouteComponentProps, Switch,
} from "react-router-dom";

import "./assets/stylesheets/themes/vwpfs/main.scss";
import { I18n } from "@aws-amplify/core";
import { App } from "./App";
import { BaseConsumer, BaseProvider } from "./components/core/03-base/Base";
import { FlexWrapperFocus } from "./components/core/03-base/FlexWrapper";
import registerServiceWorker from "./serviceWorker";
import { createReduxStore } from "./store/ReduxStore";
import { Themes } from "./theme/_Types";
import { ErrorBoundary } from "./utils/Boundary";
import { MaryProvider } from "./utils/redux/MaryProvider";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { PreLoader } from "./components/core/03-base/Preloader";
import { I18nLanguage, I18nMarylocales } from "./utils/translations/I18n";
import { I18nAppTranslationsNL } from "./App/utils/translations/nl/I18n";
import { I18nAmplifyAuthTranslationsNL, I18nMaryTranslationsNL } from "./utils/translations/nl/I18n";

I18n.configure(I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...I18nAmplifyAuthTranslationsNL,
        ...I18nMaryTranslationsNL,
        ...I18nAppTranslationsNL,
    },
});


const Root: React.FunctionComponent<{}> = ({}) => {
    const store = createReduxStore({
        apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "",
    });
    return (
        <BaseProvider
            theme={Themes.VWPFS}
            language={I18nLanguage.DUTCH}
        >
            <ErrorBoundary>
                <Provider store={store}>
                    <Router>
                        <MaryProvider flexDefaultFocus={FlexWrapperFocus.CONTENT} >
                            <VerifyContextWrapper>
                                <BaseConsumer>{({ setTheme }) =>
                                    (
                                        <Switch>
                                            <Route
                                                render={(p: RouteComponentProps ) => (
                                                    <App {...p} setTheme={setTheme} />
                                                )}
                                            />
                                        </Switch>
                                    )}
                                </BaseConsumer>
                            </VerifyContextWrapper>
                            <PreLoader />
                        </MaryProvider>
                    </Router>
                </Provider>
            </ErrorBoundary>
        </ BaseProvider>);
};

const elm = document.getElementById("root") as HTMLElement;
if (elm) {
    ReactDOM.render(
        <Root />,
        elm
    );
}

registerServiceWorker();
