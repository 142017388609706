import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from "axios";
import { Opt } from "../utils/Optional";

import { DispatchFunc } from "./ActionTypes";
import { ReduxState, RemoteState } from "./ReduxState";
import { FormRegisterDRARequest } from "./DowntimeRegistrations/Types";

export const RESULTS_LIMIT = 50;

export enum RemoteErrorType {
    DEFAULT = "DEFAULT",
    VALIDATION = "VALIDATION",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type MethodType = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export interface HeadersType {
    [key: string]: string;
}

export interface RemoteConfig<T extends RemoteScope> {
    scope: T;
    identifier?: string;
    pathMapper: string | ((state: ReduxState, ctx: RemoteContexts[T]) => string);
    responseMapper?: (resp: AxiosResponse<any>, state: ReduxState, ctx: RemoteContexts[T]) => Opt<{}>;
    method?: MethodType;
    bodyMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {} | undefined;
    source?: CancelTokenSource;
    paramsMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {};
    resMapper: (resp: AxiosResponse, s: ReduxState, ctx: RemoteContexts[T]) => RemoteState[T];
    onSuccess?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onInit?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    headers?: HeadersType;
    dontIncludeAuthHeader?: boolean;
    responseType?: ResponseType;
}

// TODO: Remove if not used
export interface RequestObject {
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface RefObject {
    id: number;
}

/**
 *
 */
export type RemoteStatusValues = Readonly<{
    Statuses: Array<{
        Label: string;
        Name: string;
    }>;
}>;

/**
 *
 */
export enum RemoteScope {
    REGISTER_DRA = "REGISTER_DRA",
    GET_ID_DRA = "GET_ID_DRA",
}

export interface DynamicResultsContext {
    skip: number;
    path: string;
    parameters?: {
        [key: string]: string;
    };
}

/**
 *
 */
export interface RemoteContexts {
    [RemoteScope.REGISTER_DRA]: {
        id: string;
        body: FormRegisterDRARequest;
    };
    [RemoteScope.GET_ID_DRA]: {
        id: string;
    };
}

/**
 * FIXME user auth headers should be included as part of the state's cached
 * AxiosInstance.
 */
export const buildRequest =
    async <T extends RemoteScope>(
        state: ReduxState,
        trigger: RemoteConfig<T>,
        ctx: RemoteContexts[T],
    // eslint-disable-next-line @typescript-eslint/require-await
    ): Promise<AxiosRequestConfig> => {
        let headers: HeadersType = {
            // 'defaultHeader': 'test'
        };

        if (trigger.headers) {
            headers = { ...headers, ...trigger.headers };
        }

        return {
            method: trigger.method || "GET",
            url: `${typeof trigger.pathMapper === "string"
                ? trigger.pathMapper : trigger.pathMapper(state, ctx)}`,
            // headers: state.mapProp("mary", u => u.map(authHeader).getOpt()),
            headers,
            responseType: trigger.responseType,
            params: !trigger.paramsMapper ? undefined : trigger.paramsMapper(state, ctx),
            data: !trigger.bodyMapper ? undefined : trigger.bodyMapper(state, ctx),
        };
    };
