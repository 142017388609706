import { hasValueValidation, ValidationFeedback } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { ValidationStatus } from "../../../../../components/core/07-organisms/Form/_Types";
import { Exceeded, ExceededReason, FormRegisterDRARequest } from "../../../../../store/DowntimeRegistrations/Types";
import { formatDateString } from "../../../../../utils/FormatHelpers";

export const isDisabled = (state: FormRegisterDRARequest) => {
    console.log(state, "isDisabled");
    const defaultFieldKeys: Array<keyof FormRegisterDRARequest> = [
        "downtimeDateStarted",
        "downtimeDateEnded",
        "downtimeTimeStarted",
        "downtimeTimeEnded",
        "exceeded"];
    const defaultExceededFields: Array<keyof ExceededReason> = [
        "downtimeDateStarted",
        "downtimeTimeStarted",
        "downtimeDateEnded",
        "downtimeTimeEnded",
        "reason",
    ];
    const allExceededFieldsAreValid = () => {
        let valid = true;
        if(state.exceeded === Exceeded.CUSTOMER || state.exceeded === Exceeded.DEALER_AND_CUSTOMER){
            valid = defaultExceededFields.every(k  => (
                hasValueValidation(state?.exceededByCustomer?.[k] as string | number ?? "", "")
                    ?.status === ValidationStatus.SUCCESS));
        }
        if(state.exceeded === Exceeded.DEALER || state.exceeded === Exceeded.DEALER_AND_CUSTOMER){
            valid = defaultExceededFields.every(k  => (
                hasValueValidation(state?.exceededByDealer?.[k] as string | number ?? "", "")
                    ?.status === ValidationStatus.SUCCESS));
        }
        if(valid && state.exceeded === Exceeded.DEALER_AND_CUSTOMER) {
            valid = hasExceededDateValidation(
                state.exceededByCustomer,state.exceededByDealer, "", "").status === ValidationStatus.SUCCESS;
        }
        return valid;
    };

    const allDefaultFieldsValid =  defaultFieldKeys.every(k  => (
        hasValueValidation(state[k] as string | number ?? "", "")?.status === ValidationStatus.SUCCESS));

    return !allDefaultFieldsValid || !allExceededFieldsAreValid();
};

export const hasExceededDateValidation =
(
    fields?: Partial<ExceededReason>,
    otherFields?: Partial<ExceededReason>,
    otherFieldsLabel?: string,
    message?: string,
    errorMessage?: string,
): ValidationFeedback => {
    if (((otherFields?.downtimeDateStarted && fields?.downtimeDateStarted)
    && (otherFields?.downtimeDateEnded && fields?.downtimeDateEnded))) {

        if(((fields.downtimeDateStarted > otherFields.downtimeDateStarted)
        && (fields.downtimeDateStarted < otherFields.downtimeDateEnded)) || (
            (fields.downtimeDateStarted < otherFields.downtimeDateStarted)
            && (fields.downtimeDateEnded > otherFields?.downtimeDateStarted)
        ) || ((fields.downtimeDateEnded < otherFields.downtimeDateEnded)
        && (fields.downtimeDateEnded > otherFields.downtimeDateStarted)) || (
            (fields.downtimeDateEnded > otherFields.downtimeDateEnded)
            && (fields.downtimeDateStarted < otherFields?.downtimeDateEnded)
        ))  {
            return {
                status: ValidationStatus.ERROR,
                // eslint-disable-next-line max-len
                message: (errorMessage || errorMessage === "") ? errorMessage : `De geselecteerde periode conflicteert met de verwijtbare periode door ${otherFieldsLabel ?? "een ander verwijt"} namelijk: ${formatDateString(otherFields.downtimeDateStarted) ?? "onbekend"} tot ${formatDateString(otherFields.downtimeDateEnded) ?? "onbekend"}`,
            };
        }
    }

    return {
        status: ValidationStatus.SUCCESS,
        message: message,
    };
};

export const checkDateUntil = (from?: string, till?: string) => {

    const fromDate = from ? new Date(from) : undefined;
    const tillDate = till ? new Date(till) : undefined;

    if (fromDate && tillDate) {
        if (fromDate > tillDate) {
            return undefined;
        }
    }

    return till;
};

export const getMinDateForEndDate = (downtimeDateStarted?: string, maintenanceDateStarted?: string) => {
    if(downtimeDateStarted) {
        return new Date(downtimeDateStarted);
    } else if (maintenanceDateStarted) {
        return new Date(maintenanceDateStarted);
    } else {
        return undefined;
    }
};

