import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map } from "immutable";
import { Action } from "redux";
import { Themes } from "../theme/_Types";

import { Opt } from "../utils/Optional";
import { MaryState } from "../utils/redux/MaryProvider";
import { State, StateInit } from "../utils/redux/State";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { PageRef } from "./AppDisplays";
import { FormRegisterDRAObjectObjectResponse } from "./DowntimeRegistrations/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

interface TState {
    conf: Config;
    client: AxiosInstance;
    remotes: Map<RemoteScope, Opt<CancelTokenSource>>;
    remote: State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    mary?: MaryState;
    currentTheme: Themes;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.REGISTER_DRA]?: FormRegisterDRAObjectObjectResponse;
    [RemoteScope.GET_ID_DRA]?: FormRegisterDRAObjectObjectResponse;
}

export type ReduxState = State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export interface CurrentInit {
    pages: PageRef;
}

export type Filters = Readonly<{
    // [FilterScope.CLAIMS]: ClaimFilters;
}>;

export type FilterStates = {
    [K in keyof Filters]: State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({ mary: true, conf: true });

export const ReduxStateInit: (config: Config) => StateInit<TState> =
    config => (): TState => ({
        conf: config,
        client: Axios.create({
            baseURL: config.apiEndpoint,
        }),
        remotes: Map(),
        remote: State.create<RemoteState>(() => ({})),
        remoteErrors: Map(),
        mary: undefined,
        currentTheme: localStorage.getItem("currentTheme") as Themes ?? Themes.VWPFS,
    });

export const initTestState: ReduxState = State.create(ReduxStateInit({apiEndpoint: ""}));
