
import { AxiosResponse } from "axios";
import { ErrorInfoObject, Exceeded, FormRegisterDRAObjectObject, FormRegisterDRAObjectObjectResponse } from "./Types";

export const mapFormRegisterDRAResponse =
    (resp: AxiosResponse): FormRegisterDRAObjectObjectResponse => ({
        statusCode: resp?.status,
        ...(resp?.status === 200 ? {content: resp.data as FormRegisterDRAObjectObject}
            : {error: resp.data as ErrorInfoObject}),
    });

export const mapGetIdDRAResponse =
    (resp: AxiosResponse): FormRegisterDRAObjectObjectResponse => {
        const respData = resp.status === 200 ? resp.data as FormRegisterDRAObjectObject : undefined;
        return ({
            statusCode: resp?.status,
            ...(resp?.status === 200 ? {content: {
                ...respData,
                exceeded: respData?.exceeded ? respData?.exceeded : Exceeded.NONE,
            }}
                : {error: resp.data as ErrorInfoObject}),
        });
    };

