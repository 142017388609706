import { mapFormRegisterDRAResponse, mapGetIdDRAResponse } from "./DowntimeRegistrations/RemoteConfig";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.REGISTER_DRA]: {
        scope: RemoteScope.REGISTER_DRA,
        method: "PUT",
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY ?? "",
        },
        onInit: (d, _s, ctx) => {
            console.log(ctx, "the to be submitted form");
            if (!ctx.id) {
                console.error("No id provided");
            }
            d(remoteClearResponse(RemoteScope.REGISTER_DRA));
            d(remoteClearError(RemoteScope.REGISTER_DRA));
        },
        pathMapper: (_s, ctx) => `/downtime/${ctx.id ?? ""}`,
        bodyMapper: (_s, ctx) => ({
            ...ctx.body,
        }),
        resMapper: mapFormRegisterDRAResponse,
    },
    [RemoteScope.GET_ID_DRA]: {
        scope: RemoteScope.GET_ID_DRA,
        method: "GET",
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY ?? "",
        },
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GET_ID_DRA));
            d(remoteClearError(RemoteScope.GET_ID_DRA));
        },
        pathMapper: (_s, ctx) => `/downtime/${ctx.id}`,
        resMapper: mapGetIdDRAResponse,
    },
};
