import { Email, Emails } from "../Emails/Types";
import { Supplier } from "../Suppliers/Types";
import { VehicleContext } from "../Vehicles/Types";

export enum Exceeded {
    NONE = "none",
    DEALER = "dealer",
    CUSTOMER = "customer",
    DEALER_AND_CUSTOMER = "dealer_and_customer",
}


export type ExceededDowntime = Partial<{
    downtimeDateStarted: string;
    downtimeTimeStarted: string;
    downtimeDateEnded: string;
    downtimeTimeEnded: string;
}>;

export type ExceededReason = ExceededDowntime & {
    reason?: string;
    downtime?: string;
};

export type DowntimeRegistrationBase = ExceededDowntime & Partial<{
    id: string;
    amountExVat: number;
    description: string;
    downtime: string;
    maintenanceDateStarted: string;
    exceeded: Exceeded;
    exceededByDealer: ExceededReason;
    exceededByCustomer: ExceededReason;
    // disclaimer: boolean, (not needing this)
    updatable: boolean;
    recorded: boolean;
    vehicle: VehicleContext;
    supplier: Supplier;
}>;

export type DowntimeRegistration =  DowntimeRegistrationBase & Partial<{
    emailSent: boolean;
    lastEmailSent: Email;
    emails: Emails;
}>;

export type DowntimeRegistrations = DowntimeRegistration[];

export type DowntimeRegistrationFilters = Partial<{
    recorded?: boolean;
    dateStarted?: string;
}>;

// CUSTOMER
export enum CustomerCodes {
    "DEFENSIE" = 1,
    "POLITIE" = 2,
}

export const CustomerCodeDisplay: Readonly<Record<CustomerCodes, string>> = {
    [CustomerCodes.DEFENSIE]: "Defensie",
    [CustomerCodes.POLITIE]: "Politie",
};

export interface Customer {
    code: CustomerCodes;
    name: string;
}

export enum Status {
    "SUCCESS" = "SUCCESS",
    "INVALID" = "INVALID",
    "ERROR" = "ERROR",
}


export interface StatusObject {
    status: Status;
}

export interface ErrorInfoObject {
    message: string;
    code: number;
    validations?: Array<{
        property?: string;
        constraints?: {
            required: string;
        };
        value: any;
    }>;
}

export interface ErrorsObject {
    error: ErrorInfoObject[];
}

export interface StatusCodeObject {
    statusCode: number;
}

export type FormRegisterDRAObjectObject = Partial<DowntimeRegistrationBase>;
export type FormRegisterDRAObjectObjectResponse = { content?: FormRegisterDRAObjectObject }
& Partial<{error: ErrorInfoObject} & StatusCodeObject>;

export type FormRegisterDRARequest = Omit<
DowntimeRegistrationBase,
"id" | "amountExVat" | "description" | "maintenanceDateStarted" | "recorded" | "vehicle" | "supplier">;
