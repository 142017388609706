import React from "react";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Blockquote } from "../../../../components/core/05-atoms/Blockquote";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../components/core/06-molecules/ButtonsWrapper";
import { FieldSet } from "../../../../components/core/06-molecules/FieldSet";
import { FormGroup } from "../../../../components/core/07-organisms/Form/Group";
import { RTE } from "../../../../components/core/07-organisms/RTE";
import { ThemePalette, ThemeBreakpoints } from "../../../../theme/_Types";
import { formatDateString } from "../../../../utils/FormatHelpers";
import { isValidLicensePlate, formatLicensePlate } from "../../../../utils/LicensePlate";
import { ExceededFields } from "./Form/Exceeded";
import { checkDateUntil, getMinDateForEndDate, isDisabled } from "./Form/utils";
import * as FormFields from "../../../../components/core/07-organisms/Form/Field";
import { Exceeded,
    FormRegisterDRAObjectObject, FormRegisterDRARequest } from "../../../../store/DowntimeRegistrations/Types";
import { hasValueValidation } from "../../../../components/core/07-organisms/Form/Field.utils";
import { Button } from "../../../../components/core/05-atoms/Button";
import { getText } from "../../../../utils/translations/I18n";
import { ExceededDisplay } from "../../../../store/AppDisplays";

/**
 *
 */
interface Props {
    form: FormRegisterDRAObjectObject;
    onChange: (fields: FormRegisterDRARequest) => void;
    loading: boolean;
    clear: () => void;
    onSubmit: (id: string, body: FormRegisterDRARequest) => void;
}

/**
 *
 * @param props
 */
export const DRAForm: React.FC<Props> = (props) => {
    const getExceededFields = (isDealer: boolean) => {
        if(isDealer) {
            return props.form.exceededByDealer ?? {};
        } else {
            return props.form.exceededByCustomer ?? {};
        }
    };
    return (
        <>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormGroup label="Dealer">
                        <RTE>
                            <h4>{props.form.supplier?.name}</h4>
                        </RTE>
                    </FormGroup>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormGroup label="Onderhouds/reparatiedatum">
                        <RTE>
                            <h4>{formatDateString(props.form.maintenanceDateStarted)}</h4>
                        </RTE>
                    </FormGroup>
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormFields.FormField
                        type="licensePlate"
                        readonly
                        label="Kenteken"
                        placeholder="CLD-000-X"
                        value={isValidLicensePlate(props.form.vehicle?.licensePlate)
                            ? formatLicensePlate(props.form.vehicle?.licensePlate)
                            : props.form.vehicle?.licensePlate?.toUpperCase()}
                    />
                </Grid>
            </Div>
            <FieldSet title="Onderhoudsperiode">
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Startdatum"
                            type="date"
                            required
                            maxDate={new Date(Date.now() + 1)}
                            // showTimeSelect
                            onChange={(value?: string | number) =>
                                props.onChange({
                                    downtimeDateStarted: value?.toString() ?? "",
                                    downtimeDateEnded: checkDateUntil(value ? value.toString()
                                        : undefined, props.form.downtimeDateEnded),
                                    exceededByCustomer: {},
                                    exceededByDealer: {},
                                })}
                            value={props.form.downtimeDateStarted}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value,
                                        "")}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Starttijd"
                            type="time"
                            required
                            onChange={(value?: string | number) =>
                                props.onChange({ downtimeTimeStarted: value?.toString() })}
                            value={props.form.downtimeTimeStarted}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value,
                                        "")}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Einddatum"
                            type="date"
                            required
                            maxDate={new Date(Date.now() + 1)}
                            minDate={getMinDateForEndDate(
                                props.form.downtimeDateStarted, props.form.maintenanceDateStarted)}
                            onChange={(value?: string | number) =>
                                props.onChange({ downtimeDateEnded: value?.toString() ?? "" })}
                            value={props.form.downtimeDateEnded}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value,
                                        "")}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Eindtijd"
                            type="time"
                            required
                            onChange={(value?: string | number) =>
                                props.onChange({ downtimeTimeEnded: value?.toString() })}
                            value={props.form.downtimeTimeEnded}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value,
                                        "")}
                        />
                    </Grid>
                </Div>
            </FieldSet>
            <FieldSet title="Geef eventuele verwijtbare downtime op">
                <FormFields.FormField
                    label="Verwijtbare downtime door"
                    required
                    unsorted
                    options={Object.keys(Exceeded).map((e) => ({
                        label: ExceededDisplay[Exceeded[e] as Exceeded],
                        value: Exceeded[e] as Exceeded,
                    }))}
                    onChange={(value?: string | number) =>
                        props.onChange(
                            {
                                exceeded: value?.toString() as Exceeded ?? "",
                                exceededByCustomer: undefined,
                                exceededByDealer: undefined,
                            }
                        )}
                    value={props.form.exceeded}
                    validationFunction={
                        (value: string | number) =>
                            hasValueValidation(value,
                                "")}
                />
                {props.form.exceeded && props.form.exceeded !== Exceeded.NONE ?
                    <>
                        {(props.form.exceeded === Exceeded.DEALER
                            || props.form.exceeded === Exceeded.DEALER_AND_CUSTOMER) && (
                            <ExceededFields
                                fields={getExceededFields(true)}
                                otherFields={getExceededFields(false)}
                                title={"Verwijtbaar aan derden"}
                                onChange={(fields) => {
                                    props.onChange({
                                        exceededByDealer: fields,
                                    });
                                }}
                                defaultMinDate={getMinDateForEndDate(
                                    props.form.downtimeDateStarted, props.form.maintenanceDateStarted)}
                                otherFieldsLabel={"klant"}
                            />
                        )}
                        {(props.form.exceeded === Exceeded.CUSTOMER
                            || props.form.exceeded === Exceeded.DEALER_AND_CUSTOMER) && (
                            <ExceededFields
                                fields={getExceededFields(false)}
                                otherFields={getExceededFields(true)}
                                title={"Verwijtbaar aan klant"}
                                onChange={(fields) => {
                                    props.onChange({
                                        exceededByCustomer: fields,
                                    });
                                }}
                                defaultMinDate={getMinDateForEndDate(
                                    props.form.downtimeDateStarted, props.form.maintenanceDateStarted)}
                                otherFieldsLabel={"derden"}
                            />
                        )}
                    </>
                    :  undefined}
            </FieldSet>
            <Div
                className="scl-b-row"
                theme={{
                    margin: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
            >
                <Grid
                    size={{ xs: 12, md: 12 }}
                >
                    <Blockquote
                        theme={{
                            palette: ThemePalette.BRAND_ACCENT,
                        }}
                    >
                        <RTE><b>Het bovenstaande is naar waarheid ingevuld.<br />
                Eventuele documenten die aan de registratie ten grondslag
                liggen en van toepassing zijn, heeft u in uw bezit en dient u
                te kunnen overleggen.</b>
                        </RTE>
                    </Blockquote>
                </Grid>
            </Div>
            <ButtonsWrapper
                alignment={Alignment.RIGHT}
                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
            >
                <Button
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                    link={{
                        onClick: () => props.clear(),
                    }}
                    disabled={props.loading}
                >
                    {getText(
                        "mary.cancel",
                        "Cancel")}
                </Button>
                <Button
                    className="scl-a-btn--big"
                    theme={{
                        palette: ThemePalette.BRAND_ACCENT,
                    }}
                    link={{
                        onClick: () =>
                            props.onSubmit(props.form.id ?? "", {
                                exceededByCustomer: props.form.exceeded === Exceeded.NONE
                                    ? undefined : props.form.exceededByCustomer,
                                exceededByDealer: props.form.exceeded === Exceeded.NONE
                                    ? undefined :props.form.exceededByDealer,
                                downtimeDateStarted: props.form.downtimeDateStarted,
                                downtimeDateEnded: props.form.downtimeDateEnded,
                                downtimeTimeStarted: props.form.downtimeTimeStarted,
                                downtimeTimeEnded: props.form.downtimeTimeEnded,
                                exceeded: props.form.exceeded,
                            }),
                    }}
                    disabled={isDisabled(props.form)}
                >
                    Versturen
                </Button>
            </ButtonsWrapper>
        </>
    );
};

